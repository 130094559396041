import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import constants from "../../utils/constants";
import { restActionFactory } from "../../toolkit/axiosActions/axiosActionsFactory";
import { deleteEmptyProps } from "../../toolkit/utils/filter-utils";

const COMISIONES_BASE_URL = "/api/comisiones-deportiva";

// Consulta de ventas
export const getCmsProductosVenta = (params?: any): IAction => {
  return comisionesRestAction({
    actionType: actionTypes.GET_COMISIONES_V2_PRODUCTOS_VENTA,
    urlComplement: "/ventas/productos-vendidos",
    method: "get",
    params
  });
};

export const getCmsVentas = (params?: any): IAction => {
  let urlComplement = "";

  if (params && params.tipoBusqueda === "EMPLEADO") {
    urlComplement = "/ventas/empleados";
  } else if (params && params.tipoBusqueda === "FACTURA") {
    urlComplement = "/ventas/facturas";
  } else {
    urlComplement = "/ventas/productos";
  }

  return comisionesRestAction({
    actionType: actionTypes.GET_COMISIONES_V2_VENTAS,
    urlComplement,
    method: "get",
    params: deleteEmptyProps(params)
  });
};

export const putCmsVendedor = (idInstancia: any, body: any): IAction => {
  return comisionesRestAction({
    actionType: actionTypes.PUT_COMISIONES_V2_VENDEDOR,
    urlComplement: `/ventas/${idInstancia}/vendedores`,
    method: "put",
    body
  });
};

export const putCmsInstructorPrincipal = (
  idInstancia: any,
  body: any
): IAction => {
  return comisionesRestAction({
    actionType: actionTypes.PUT_COMISIONES_V2_INSTRUCTOR_PRINCIPAL,
    urlComplement: `/ventas/${idInstancia}/instructores`,
    method: "put",
    body
  });
};

export const putCmsCoordinador = (idInstancia: any, body: any): IAction => {
  return comisionesRestAction({
    actionType: actionTypes.PUT_COMISIONES_V2_COORDINADOR,
    urlComplement: `/ventas/${idInstancia}/coordinadores`,
    method: "put",
    body
  });
};

export const getCmsProductosOperacion = (params?: any): IAction => {
  return comisionesRestAction({
    actionType: actionTypes.GET_COMISIONES_V2_PRODUCTOS_OPERACION,
    urlComplement: "/productos-operacion",
    method: "get",
    params
  });
};

export const getCmsInstructorComisionable = (params?: any): IAction => {
  return comisionesRestAction({
    actionType: actionTypes.GET_COMISIONES_V2_INSTRUCTOR_COMISIONABLE,
    urlComplement: "/instructor-comisionable",
    method: "get",
    params
  });
};

export const getCmsClasesAsignables = (params?: any): IAction => {
  return comisionesRestAction({
    actionType: actionTypes.GET_COMISIONES_V2_CLASES_ASIGNABLES,
    urlComplement: "/clases-asignables",
    method: "get",
    params
  });
};

export const postCmsAsignacion = (body: any): IAction => {
  return comisionesRestAction({
    actionType: actionTypes.POST_COMISIONES_V2_ASIGNACION,
    urlComplement: "/asignacion",
    method: "post",
    body
  });
};

export const getCmsClasesAsignadas = (params?: any): IAction => {
  return comisionesRestAction({
    actionType: actionTypes.GET_COMISIONES_V2_CLASES_ASIGNADAS,
    urlComplement: "/clases-asignadas",
    method: "get",
    params
  });
};

export const putCmsClasesImparticion = (body: any): IAction => {
  return comisionesRestAction({
    actionType: actionTypes.PUT_COMISIONES_V2_EVENTO_IMPARTICION,
    urlComplement: "/impartir",
    method: "put",
    body
  });
};

export const getCmsDeportivaAutorizacion = (params?: any): IAction => {
  return comisionesRestAction({
    actionType: actionTypes.GET_COMISIONES_V2_DEPORTIVA_AUTORIZACION,
    urlComplement: "/autorizacion",
    method: "get",
    params
  });
};

export const putCmsDeportivaAutorizar = (body: any): IAction => {
  return comisionesRestAction({
    actionType: actionTypes.PUT_COMISIONES_V2_DEPORTIVA_AUTORIZAR,
    urlComplement: "/autorizar",
    method: "put",
    body
  });
};

export const putCmsDeportivaRechazar = (id: any, body: any): IAction => {
  return comisionesRestAction({
    actionType: actionTypes.PUT_COMISIONES_V2_DEPORTIVA_RECHAZAR,
    urlComplement: "/rechazar",
    method: "put",
    body
  });
};

export const putCmsDeportivaReasignar = (id: any, body: any): IAction => {
  return comisionesRestAction({
    actionType: actionTypes.PUT_COMISIONES_V2_DEPORTIVA_REASIGNAR,
    urlComplement: "/reasignar",
    method: "put",
    body
  });
};

const comisionesRestAction = restActionFactory(
  `${constants.BASE_URL}${COMISIONES_BASE_URL}`
);
