import FormBuilderConfig from "../../../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import IDateBuilder from "../../../../../toolkit/baseForms2/iDateBuilder/IDateBuilder";
import ISelectBuilder from "../../../../../toolkit/baseForms2/iSelectBuilder/ISelectBuilder";
import IClubSelectorBuilder from "../../../../../toolkit/baseForms2Commons/iClubSelectorBuilder/IClubSelectorBuilder";
import IUserSelectorBuilder from "../../../../../toolkit/baseForms2Commons/iUserSelectorBuilder/IUserSelectorBuilder";
import * as validators from "./CImparticion.validators";

export type Inputs =
  | "tipoBusqueda"
  | "idClub"
  | "fechaInicio"
  | "fechaFin"
  | "idEmpleado"
  | "folioFactura";
export type Buttons = "buscar";

export const formConfig: FormBuilderConfig<Inputs, Buttons> = {
  inputs: [
    {
      name: "tipoBusqueda",
      label: "* Tipo de búsqueda",
      bts: {
        mCol: 12
      },
      component: ISelectBuilder,
      props: {
        emptyLabel: "Seleccione una opción",
        options: [
          { value: "FECHA", label: "Fecha asignada" },
          { value: "EMPLEADO", label: "Empleado" },
          { value: "FACTURA", label: "Factura" }
        ]
      }
    },
    {
      name: "idClub",
      label: "* Club",
      bts: {
        mCol: 12
      },
      component: IClubSelectorBuilder,
      props: {
        hidden: validators.hiddenIfNotBusquedaFecha
      }
    },
    {
      name: "fechaInicio",
      label: "* Fecha de inicio",
      bts: {
        mCol: 12
      },
      component: IDateBuilder,
      props: {
        hidden: validators.hiddenIfNotBusquedaFecha
      }
    },
    {
      name: "fechaFin",
      label: "* Fecha de fin",
      bts: {
        mCol: 12
      },
      component: IDateBuilder,
      props: {
        hidden: validators.hiddenIfNotBusquedaFecha
      }
    },
    {
      name: "idEmpleado",
      label: "* Empleado",
      bts: {
        mCol: 12
      },
      component: IUserSelectorBuilder,
      props: {
        hidden: validators.hiddenIfNotBusquedaEmpleado
      }
    },
    {
      name: "folioFactura",
      label: "* Folio de factura",
      bts: {
        mCol: 12
      },
      component: ISelectBuilder,
      props: {
        emptyLabel: "Seleccione una opción",
        options: [],
        hidden: validators.hiddenIfNotBusquedaFactura
      }
    }
  ],
  buttons: [
    {
      name: "buscar",
      label: "Buscar",
      isSubmit: true,
      bts: {
        mCol: 4
      }
    }
  ],
  validations: {
    tipoBusqueda: {
      required: true
    },
    idClub: {
      requiredIfBusquedaFecha: validators.requiredIfBusquedaFecha
    },
    fechaInicio: {
      requiredIfBusquedaFecha: validators.requiredIfBusquedaFecha
    },
    fechaFin: {
      requiredIfBusquedaFecha: validators.requiredIfBusquedaFecha
    },
    idEmpleado: {
      requiredIfBusquedaEmpleado: validators.requiredIfBusquedaEmpleado
    },
    folioFactura: {
      requiredIfBusquedaFactura: validators.requiredIfBusquedaFactura
    }
  },
  rows: []
};
