export default {
  //PARTNER MEMBERSHIPS
  PARTNER_MEMBERSHIP_CHANGE_STATUS: 12101,
  PARTNER_MEMBERSHIP_DISASSOCIATE_AGREEMENT: 12102,
  PARTNER_MEMBERSHIP_ASSOCIATE_PROMOTION: 12103,
  PARTNER_MEMBERSHIP_ASSOCIATE_ALTERNATE_CLUB: 121030,
  PARTNER_MEMBERSHIP_TAB_PARTNERS: 12105,
  PARTNER_MEMBERSHIP_TAB_PARTNERS_CHANGE_TITULAR: 12106,
  PARTNER_MEMBERSHIP_TAB_PARTNERS_ACCESS_CLUB_EXTENSION: 12107,
  PARTNER_MEMBERSHIP_TAB_PARTNERS_DELETE_FREE_GUEST_ACCESS: 12108,
  PARTNER_MEMBERSHIP_TAB_PARTNERS_FORCE_BLOCK_CLUB_ACCESS: 12109,
  PARTNER_MEMBERSHIP_TAB_PARTNERS_ACCESS_CLUB_EXTRA_CONFIG: 12111,
  PARTNER_MEMBERSHIP_TAB_DOCUMENTS: 12110,
  PARTNER_MEMBERSHIP_TAB_SURVEYS: 12115,
  PARTNER_MEMBERSHIP_TAB_TOKEN: 12120,
  PARTNER_MEMBERSHIP_TAB_TOKEN_FORCE_DELETE: 12121,
  PARTNER_MEMBERSHIP_TAB_TOKEN_ALLOW_DELETE_PAYMENT_METHOD: 12122,
  PARTNER_MEMBERSHIP_TAB_TOKEN_ALLOW_DELETE_PAYMENT_METHOD_NO_SURVEY: 12123,
  PARTNER_MEMBERSHIP_TAB_TOKEN_ALLOW_DELETE_PAYMENT_METHOD_AFTER_20_DAYS: 121220,
  PARTNER_MEMBERSHIP_TAB_TOKEN_ALLOW_COPY_PAYMENT_METHOD: 121221,
  PARTNER_MEMBERSHIP_TAB_TRAM: 12125,
  PARTNER_MEMBERSHIP_TAB_TRAM_CANCEL_MEMBERSHIP: 12124,
  PARTNER_MEMBERSHIP_TAB_TRAM_TRANSFER_CLUB: 12127,
  PARTNER_MEMBERSHIP_TAB_TRAM_CESION: 12128,
  PARTNER_MEMBERSHIP_TAB_TRAM_CHANGE_TITULAR: 12129,
  PARTNER_MEMBERSHIP_TAB_TRAM_CHANGE_PREVIOUS_MAINTENANCE: 121290,
  PARTNER_MEMBERSHIP_TAB_MOVEMENTS: 12130,
  PARTNER_MEMBERSHIP_TAB_MOVEMENTS_ADD_DISCOUNT: 12131,
  PARTNER_MEMBERSHIP_TAB_MOVEMENTS_GENERATE_CURRENT_MONTH: 12132,
  PARTNER_MEMBERSHIP_TAB_MOVEMENTS_GENERATE_IGUALA_MAINTENANCE: 121320,
  PARTNER_MEMBERSHIP_TAB_MOVEMENTS_GENERATE_ACCESS_FREEGUESTS: 12133,
  PARTNER_MEMBERSHIP_TAB_MOVEMENTS_ANNUITY_ZUMA_TIEMPO: 12134,
  PARTNER_MEMBERSHIP_TAB_MOVEMENTS_MAINTENACE_ENABLE_CAT: 121341,
  PARTNER_MEMBERSHIP_TAB_MOVEMENTS_MAINTENACE_EXCLUDE_IN_CAT: 121342,
  PARTNER_MEMBERSHIP_TAB_MOVEMENTS_DUPLICATE_DELETED_MOVEMENT: 121343,
  PARTNER_MEMBERSHIP_TAB_MOVEMENTS_REVERT_PAYMENT_EXCEPTION: 121344,
  PARTNER_MEMBERSHIP_TAB_MESSAGES: 12135,
  PARTNER_MEMBERSHIP_TAB_ADE: 12140,
  PARTNER_MEMBERSHIP_TAB_REACTIVATION: 12145,
  PARTNER_MEMBERSHIP_TAB_ACCESS: 12150,
  PARTNER_MEMBERSHIP_TAB_CAT_HIST: 12155,
  PARTNER_MEMBERSHIP_TAB_DEVOL: 12160,
  PARTNER_MEMBERSHIP_TAB_ANNUITIES: 12165,
  PARTNER_MEMBERSHIP_TAB_ANNUITIES_PAST_MONTHS_QUOTATIONS: 12166,
  PARTNER_MEMBERSHIP_TAB_DISCOUNT_CODES: 12170,
  PARTNER_MEMBERSHIP_TAB_DISCOUNT_CODES_GENERATE_GUESTS_PASSES: 12172,
  PARTNER_MEMBERSHIP_TAB_DISCOUNT_CODES_DELETE_DISCOUNT_CODE: 12173,
  PARTNER_MEMBERSHIP_TAB_DISCOUNT_CODES_GENERATE_PRODUCT_DISCOUNT: 12174,
  PARTNER_MEMBERSHIP_ASSIGN_AGGREMENT_PERMISSION: 12126,
  PARTNER_MEMBERSHIP_TAB_BINNACLE: 12171,
  PARTNER_MEMBERSHIP_TAB_BINNACLE_ADD_MESSAGE: 121710,
  PARTNER_MEMBERSHIP_TAB_VACATION_FEE: 12175,
  PARTNER_MEMBERSHIP_TAB_VACATION_FEE_CREATE_REQUEST: 12176,
  PARTNER_MEMBERSHIP_TAB_VACATION_FEE_APPLY_REQUEST: 12177,
  PARTNER_MEMBERSHIP_TAB_LICENCIA_EXTRANJERO: 12178,
  PARTNER_MEMBERSHIP_TAB_REFERRED_UNTIMELY: 12191,
  //--------
  SALES_TRACKING_MONTH_COMPROMISES_EDITION_PERMISSION: 46801,
  SALES_TRACKING_SELLER_COMPROMISES_EDITION_PERMISSION: 46802,
  SALES_TRACKING_NEW_ACTIVITY_CREATION_PERMISSION: 46803,
  //PROSPECTS
  PROSPECT_CREATION_PERMISSION: 11200,
  PROSPECT_REASIGN_PERMISSION: 11300,
  PROSPECT_CHANGE_INTEREST_STATUS_PERMISSION: 11400,
  PROSPECT_CREATE_ACTIONS_PERMISSION: 11500,
  PROSPECT_EDITION_PERMISSION: 11600,
  PROSPECT_BASIC_DATA_EDITION_PERMISSION: 11610,
  PROSPECT_DETAIL_EDITION_PERMISSION: 11620,
  PROSPECT_VIEW_LOG_PERMISSION: 11700,
  PROSPECT_DELETE_PERMISSION: 11800,
  PROSPECT_VIEW_APPOINMENT_SCHEDULE_PERMISSION: 11900,
  PROSPECT_EDITION_BANDERA_EXTRANJERO: 11611,
  PROSPECT_EDITION_OMITIR_CURP: 11612,
  PROSPECT_EDITION_OMITIR_VERIFICACION: 11613,
  //PARTNERTS
  PARTNER_EDITION_PERMISSION: 11955,
  PARTNER_BASIC_DATA_EDITION_PERMISSION: 11956,
  PARTNER_DETAIL_EDITION_PERMISSION: 11957,
  // SPECIAL GUESTS DEPRECATED
  SPECIAL_GUESTS_EDITION_PERMISSION: 11965,
  SPECIAL_GUESTS_BASIC_DATA_EDITION_PERMISSION: 11966,
  SPECIAL_GUESTS_DETAIL_EDITION_PERMISSION: 11967,
  //EXTERN
  EXTERN_EDITION_PERMISSION: 11972,
  EXTERN_BASIC_DATA_EDITION_PERMISSION: 11974,
  EXTERN_DETAIL_EDITION_PERMISSION: 11976,
  EMPLOYEE_EDITION_PERMISSION: 11977,
  EMPLOYEE_BASIC_DATA_EDITION_PERMISSION: 11978,
  EMPLOYEE_DETAIL_EDITION_PERMISSION: 11979,
  //CONCESIONARIOS
  CONCESSIONAIRE_CREATE_CHARGE_CONFIG: 51201,
  CONCESSIONAIRE_EDIT_CHARGE_CONFIG: 51202,
  CONCESSIONAIRE_DELETE_CHARGE_CONFIG: 51203,
  CONCESSIONAIRE_VALIDATE_CHARGE_CONFIG: 51204,
  //CASHBOX
  CASHBOX_DELETE_PERMISSION: 12255,
  //PROMOTIONS
  PROMOTIONS_AUTHORIZE_PERMISSION: 24500,
  //PRODUCTS
  PRODUCTS_VIEW_PRODUCTS: 25100,
  PRODUCTS_CREATE_PRODUCT: 25200,
  PRODUCTS_EDIT_PRODUCT: 25300,
  //SHOPPING_CART
  SHOPPING_CART_DELETE_MEMBRESHIP_PERMISSION: 25431,
  SHOPPING_CART_DELETE_MAINTENANCE_PERMISSION: 25432,
  SHOPPING_CART_DELETE_ANNUITY_PERMISSION: 25433,
  SHOPPING_CART_DELETE_PRODUCT_AND_CHARGES_PERMISSION: 25434,
  SHOPPING_CART_UPDATE_AMOUNT_MEMBRESHIP_PERMISSION: 25441,
  SHOPPING_CART_UPDATE_AMOUNT_MAINTENANCE_PERMISSION: 25442,
  SHOPPING_CART_UPDATE_AMOUNT_ANNUITY_PERMISSION: 25443,
  SHOPPING_CART_UPDATE_AMOUNT_PRODUCT_AND_CHARGES_PERMISSION: 25444,
  SHOPPING_CART_PAYMENT_EXCEPTION_MEMBRESHIP_PERMISSION: 25451,
  SHOPPING_CART_PAYMENT_EXCEPTION_MAINTENANCE_PERMISSION: 25452,
  SHOPPING_CART_PAYMENT_EXCEPTION_PRODUCT_PERMISSION: 25453,
  SHOPPING_CART_UPDATE_DESCRIPTION_PERMISSION: 25435,
  SHOPPING_CART_UPDATE_DATE_PERIOD_PERMISSION: 25436,
  SHOPPING_CART_DISABLE_FORCE_CAT_MAINTENANCE_PERMISSION: 25458,
  SHOPPING_CART_MULTIPLE: 25445,
  PAYMENT_LINEAS_DE_PAGO: 25460,

  //CASHBOX
  CASHBOX_PAYMENT_FORM_99: 122500,

  //ANNUITIES
  ANNUITIES_EDIT_CONFIG: 28125,

  //COMMISSIONS
  COMMISSIONS_SALE_REASIGN: 46201,
  COMMISSIONS_PRODUCTS_REASIGN: 46301,
  COMMISSIONS_AUTHORIZATION: 29954,

  //REPORTS
  REPORT_CLUBS_CAT_CONFIG_ACTIVATE_CLUB_FOR_CAT_PROCESS: 41601,
  REPORT_CLUBS_CAT_CONFIG_EXECUTE_MANUAL_CAT_PROCESS: 41602,
  REPORT_SALES_WITH_ERRORS_REFACT: 46711,
  REPORT_CAT_PRELIMINAR_SEARCH_ALL_CLUBS: 46891,
  REPORT_PARTNER_MEMBERSHIP_DIRECTORY_SEARCH_ALL_CLUBS: 46895,

  // Solicitud de notas de crédito; este permiso existió hasta que se creó la cancelación
  SOLICITAR_NOTA_CREDITO: 46555,

  // Autorización de cancelaciones y notas de crédito
  AUTORIZAR_NOTA_CREDITO_DATOS_FISCALES: 46551,
  AUTORIZAR_NOTA_CREDITO_COBRANZA_ERRONEA: 46552,
  RECHAZAR_SOLICITUD_NOTA_CREDITO_DATOS_FISCALES: 46553,
  RECHAZAR_SOLICITUD_NOTA_CREDITO_COBRANZA_ERRONEA: 46554,

  AUTORIZAR_CANCELACION_DATOS_FISCALES: 46556,
  AUTORIZAR_CANCELACION_COBRANZA_ERRONEA: 46557,
  RECHAZAR_SOLICITUD_CANCELACION_DATOS_FISCALES: 46558,
  RECHAZAR_SOLICITUD_CANCELACION_COBRANZA_ERRONEA: 46559,

  CONFIRMAR_CANCELACION_MANUALMENTE: 46570,

  // Expedientes
  AUTORIZACION_EXPEDIENTES: 12210,

  // Reportes de Roles
  ROLES_DESCARGAR_REPORTES: 31400,

  // Invitados especiales
  INVITADO_ACTIVAR: 11961,
  INVITADO_INACTIVAR: 11962,
  INVITADO_ELIMINAR: 11963,
  INVITADO_CREAR: 11964,
  INVITADO_EDITAR: 11965,
  INVITADO_TAB_DOCUMENTACION: 11966,
  INVITADO_TAB_DAC: 11967,
  INVITADO_TAB_DATOS: 11968,
  INVITADO_TAB_MOVIMIENTOS: 11969,
  INVITADO_TAB_BITACORA: 11980,
  // Apartado
  INVITADO_TAB_ACCESOS: 11981,

  // Permisos para Comisiones (V2) - Ventas de deportiva
  COMISIONES_V2_ACTUALIZAR_VENDEDOR: 12101,
  COMISIONES_V2_ACTUALIZAR_COORDINADOR: 12101,
  COMISIONES_V2_ACTUALIZAR_INSTRUCTOR_PRINCIPAL: 12101,
  COMISIONES_V2_ACTUALIZAR_EMPLEADO_IGNORAR_PUESTO: 12101,

  // Permisos para Comisiones (V2) - Asignación de clases
  COMISIONES_V2_ASIGNAR_EVENTO: 12101,
  COMISIONES_V2_ASIGNAR_EVENTO_IGNORAR_PUESTO: 12101,

  // Permisos para Comisiones (V2) - Impartición de clases
  COMISIONES_V2_IMPARTICION_EVENTO: 12101,
  COMISIONES_V2_IMPARTICION_EVENTO_ACTUALIZACION: 12101,
  COMISIONES_V2_IMPARTICION_EVENTO_IGNORAR_PUESTO: 12101,
  COMISIONES_V2_IMPARTICION_EVENTO_IGNORAR_CODIGO: 12101,

  // Permisos para Comisiones (V2) - Autorización de comisiones
  COMISIONES_V2_AUTORIZAR: 12101,
  COMISIONES_V2_RECHAZAR: 12101,
  COMISIONES_V2_REASIGNAR: 12101
};
