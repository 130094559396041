import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { Button, UncontrolledTooltip } from "reactstrap";
import s from "../../styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faCameraRetro,
  faEye,
  faFileExport,
  faFingerprint,
  faInfoCircle,
  faPencilAlt,
  faPlus,
  faTrash,
  faUserCheck,
  faUserLock
} from "@fortawesome/free-solid-svg-icons";
import permissionCodes from "../../../../../utils/permissionCodes";
import { userHasPermission } from "../../../../../utils/accessTree";
import DeportivaEventoAsignable from "../../../../../types/DeportivaEventoAsignable";

const { COMISIONES_V2_ASIGNAR_EVENTO: ASIGNAR_EVENTO } = permissionCodes;

export interface TableProps {
  page: Page<DeportivaEventoAsignable>;
  loading: boolean;
  onFetchData: ({ page, pageSize }) => void;
  onExport: () => void;
  onAsignar: (row: DeportivaEventoAsignable) => void;
  onVerMembresia: (row: DeportivaEventoAsignable) => void;
}

export const MemoTable = React.memo(
  ({
    page,
    loading,
    onFetchData,
    onExport,
    onAsignar,
    onVerMembresia
  }: TableProps) => {
    // State de las columnas de la tabla
    const [columns, setColumns] = useState(
      columnsFormatter(onAsignar, onVerMembresia)
    );
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);

    // Actualización de estilos
    useEffect(() => {
      setColumns(columnsFormatter(onAsignar, onVerMembresia));
    }, []);

    // Actualización de datos de paginación
    useEffect(() => {
      if (page) {
        setPageNumber(page.number);
        setTotalPages(page.totalPages);
      }
    }, [page]);

    const mostrados = page && page.numberOfElements;
    const totales = page && page.totalElements;
    return (
      <>
        {/* Botón para exportar */}
        <div className="row">
          {!!page && !!page.content && !!page.content.length && (
            <>
              <div className="col-6 p-0" style={{ lineHeight: "40px" }}>
                <span>
                  Invitados mostrados: {mostrados} de {totales}
                </span>
              </div>
              <div
                className="col-6 p-0 text-right"
                style={{ lineHeight: "40px" }}
              >
                <Button
                  hidden
                  color="primary"
                  onClick={onExport}
                  disabled={loading}
                  className="mr-2"
                >
                  <FontAwesomeIcon icon={faFileExport} />
                  {!loading ? " Exportar" : " Espere..."}
                </Button>
              </div>
            </>
          )}
        </div>

        {/* Tabla */}
        <div className="row mt-2">
          <div style={{ width: "100%" }}>
            <ReactTable
              manual
              data={(page && page.content) || []}
              columns={columns}
              loading={loading}
              defaultPageSize={50}
              className="-striped -highlight"
              onFetchData={onFetchData}
              page={pageNumber}
              pages={totalPages}
              showPagination={true}
              showPageSizeOptions={false}
              getTrProps={() => ({ style: { height: "30px" } })}
              sortable={false}
            />
          </div>
        </div>
      </>
    );
  }
);

// TODO: Definir permisos para los botones
function columnsFormatter(
  onAsignar: (row: DeportivaEventoAsignable) => void,
  onVerMembresia: (row: DeportivaEventoAsignable) => void
) {
  return [
    {
      Header: "Factura",
      id: "folioFactura",
      className: s.tdClear,
      accessor: simpleAccessor("folioFactura")
    },
    {
      Header: "Producto",
      id: "producto",
      className: s.tdClear,
      accessor: simpleAccessor("producto")
    },
    {
      Header: "Fecha de venta",
      id: "fechaVenta",
      className: s.tdClear,
      accessor: simpleAccessor("fechaVenta")
    },
    {
      Header: "Membresía",
      id: "membresia",
      className: s.tdClear,
      width: 250,
      accessor: (row: DeportivaEventoAsignable) => {
        return (
          <div className={`${fieldClassName(row)} text-center`}>
            <a
              hidden={!!row.idPublico}
              href="#"
              onClick={e => {
                e.preventDefault();
                onVerMembresia(row);
              }}
            >
              {row.clubMembresia} - {row.idPublico}
            </a>
            <span hidden={!row.idPublico}>No aplica</span>
          </div>
        );
      }
    },
    {
      Header: "Id Cliente",
      id: "idPersona",
      className: s.tdClear,
      accessor: simpleAccessor("idPersona")
    },
    {
      Header: "Cliente",
      id: "nombrePersona",
      className: s.tdClear,
      accessor: simpleAccessor("nombrePersona")
    },
    {
      Header: "Disponibles",
      id: "eventosDisponibles",
      className: s.tdClear,
      accessor: simpleAccessor("eventosDisponibles")
    },
    {
      Header: "",
      id: "acciones",
      className: s.tdClear,
      width: 250,
      accessor: (row: DeportivaEventoAsignable) => {
        return (
          <div className={`${fieldClassName(row)} text-center`}>
            {renderBotonAsignar(row, onAsignar)}
          </div>
        );
      }
    }
  ];
}

function simpleAccessor(key: keyof DeportivaEventoAsignable) {
  return (row: DeportivaEventoAsignable) => {
    return <div className={fieldClassName(row)}>{row[key]}&nbsp;</div>;
  };
}

function fieldClassName(row: DeportivaEventoAsignable) {
  return row.asignable ? `${s.field}` : `${s.field} ${s.noAsignable}`;
}

function renderBotonAsignar(row: DeportivaEventoAsignable, action) {
  if (!userHasPermission(ASIGNAR_EVENTO)) {
    return false;
  }

  const idBoton = "id-" + row.idProductoInstancia + "-asignar";
  let colorButton = "#008080";
  let tooltip: any = row.esClasePersonalizada
    ? "Asignar clase"
    : "Añadir participante";
  let habilitado = true;

  if (!row.asignable) {
    habilitado = false;
    colorButton = "#600";
    tooltip = row.razonesNoAsignable.map(r => (
      <>
        {r}
        <br />
      </>
    ));
  }

  return renderBoton(
    row,
    idBoton,
    habilitado,
    action,
    tooltip,
    colorButton,
    faInfoCircle
  );
}

function renderBoton(
  row: DeportivaEventoAsignable,
  id,
  habilitado,
  action,
  tooltip,
  color,
  icon
) {
  if (habilitado) {
    return (
      <span className={s.info} id={id}>
        <FontAwesomeIcon icon={icon} color={color} />
        <UncontrolledTooltip placement="left" target={id}>
          {tooltip}
        </UncontrolledTooltip>
      </span>
    );
  } else {
    return (
      <span id={id} onClick={() => action(row)} className={s.btn}>
        <FontAwesomeIcon icon={icon} color={color} />
        <UncontrolledTooltip placement="left" target={id}>
          {tooltip}
        </UncontrolledTooltip>
      </span>
    );
  }
}
