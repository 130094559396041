import FormBuilderConfig from "../../../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import IButtonBuilder from "../../../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";
import ISelectBuilder from "../../../../../toolkit/baseForms2/iSelectBuilder/ISelectBuilder";
import IUserSelectorBuilder from "../../../../../toolkit/baseForms2Commons/iUserSelectorBuilder/IUserSelectorBuilder";
import { userHasPermission } from "../../../../../utils/accessTree";
import permissionCodes from "../../../../../utils/permissionCodes";
import * as validators from "./AVenta.validators";

const {
  COMISIONES_V2_ACTUALIZAR_EMPLEADO_IGNORAR_PUESTO: IGNORAR_PUESTO
} = permissionCodes;

export type Inputs = "idEmpleado" | "ignorarPuesto" | "razonIgnorarPuesto";
export type Buttons = "aceptar" | "cancelar";

export const formConfigEmpleado: FormBuilderConfig<Inputs, Buttons> = {
  inputs: [
    {
      name: "idEmpleado",
      label: "* Nuevo empleado",
      bts: {
        mCol: 12
      },
      component: IUserSelectorBuilder
    },
    {
      name: "ignorarPuesto",
      label: "Ignorar puesto de trabajo",
      bts: {
        mCol: 12
      },
      component: ISelectBuilder,
      props: {
        emptyLabel: "No",
        data: [{ value: "true", label: "Si" }],
        hidden: () => !userHasPermission(IGNORAR_PUESTO)
      }
    },
    {
      name: "razonIgnorarPuesto",
      label: "Razón",
      bts: {
        mCol: 12
      },
      component: ISelectBuilder,
      props: {
        emptyLabel: "Seleccione una opción",
        data: [
          {
            value: "Personal no cubierto en el puesto",
            label: "Personal no cubierto en el puesto"
          },
          {
            value: "Baja inesperada de colaborador(a)",
            label: "Baja inesperada de colaborador(a)"
          },
          {
            value: "Excepción aceptada por T. y C.",
            label: "Excepción aceptada por T. y C."
          }
        ],
        hidden: () => !userHasPermission(IGNORAR_PUESTO),
        disabled: formValue => formValue.ignorarPuesto !== "true"
      }
    }
  ],
  buttons: [
    {
      name: "aceptar",
      label: "Aceptar",
      component: IButtonBuilder,
      isSubmit: true,
      bts: {
        mOff: 4,
        mCol: 4
      }
    },
    {
      name: "cancelar",
      label: "Cancelar",
      bts: {
        mCol: 4
      }
    }
  ],
  validations: {
    idEmpleado: {
      required: true
    },
    razonIgnorarPuesto: {
      requiredIfIgnorarPuesto: {
        validator: (value, formValue) =>
          formValue.ignorarPuesto === "true" && !value,
        message: () => "Campo obligatorio"
      }
    }
  },
  rows: [
    ["idEmpleado", "ignorarPuesto", "razonIgnorarPuesto"],
    ["aceptar", "cancelar"]
  ]
};
