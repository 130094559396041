import React, { Component } from "react";
import {
  RouterProps,
  Styled
} from "../../../../../toolkit/decorators/Artifact";
import { Container } from "reactstrap";
import FormBuilder from "../../../../../toolkit/baseForms2/formBuilder/FormBuilder";
import { Inputs, Buttons, formConfig } from "./BAsignacion.form";
import { MemoTable } from "./BAsignacion.table";
import DeportivaEventoAsignable from "../../../../../types/DeportivaEventoAsignable";
import { deleteEmptyProps } from "../../../../../toolkit/utils/filter-utils";
import { errorNotification } from "../../../../../utils/notifications";
import s from "../../styles.scss";
import ITypeSelectBuilder from "../../../../../toolkit/baseForms2/iTypeSelectBuilder/ITypeSelectBuilder";
import FormBuilderModal from "../../../common/formBuilderModal/formBuilderModal";
import { BAsignacionDispatcher } from "../../../../containers/comisionesDeportivaV2Carrusel/steps/BAsignacion.dispatcher";
import { BAsignacionStore } from "../../../../containers/comisionesDeportivaV2Carrusel/steps/BAsignacion.store";
import { formConfigClase } from "./BAsignacionClase.form";
import { formConfigPrograma } from "./BAsignacionPrograma.form";

interface Props extends BAsignacionDispatcher, BAsignacionStore, RouterProps {}

interface State {
  downloading: boolean;
  waiting: boolean;
  filters: Inputs & {
    page: number;
    size: number;
  };

  // Actualización de empleado
  rowPopup: DeportivaEventoAsignable;
  clasePopupOpen: boolean;
  clasePopupTitle: string;
  clasePopupText: string;
  programaPopupOpen: boolean;
  programaPopupTitle: string;
  programaPopupText: string;
}

@Styled(s)
export default class BAsignacionComponent extends Component<Props, State> {
  state = {
    downloading: false,
    waiting: false,
    filters: {
      page: 0,
      size: 10
    } as any,

    // Actualización de empleado
    rowPopup: null as DeportivaEventoAsignable,
    clasePopupOpen: false,
    clasePopupTitle: "",
    clasePopupText: "",
    programaPopupOpen: false,
    programaPopupTitle: "",
    programaPopupText: ""
  };

  componentDidMount(): void {
    this.fetchData({} as any);
  }

  submit = (values: any) => {
    // Búsqueda de datos en página 1
    this.fetchData({ page: 0 } as any, null, values);
  };

  // Carga de datos para poblar la tabla
  fetchData = async (
    { pageSize: tablePageSize, page: tablePage },
    _?: any,
    formFilters?
  ) => {
    const filters = formFilters || this.state.filters;
    const page = tablePage >= 0 ? tablePage : this.state.filters.page;
    const size = tablePageSize >= 0 ? tablePageSize : this.state.filters.size;
    const pageableFilters = deleteEmptyProps({ ...filters, page, size });

    this.setState({ filters: pageableFilters });

    const preview = await this.props.getCmsClasesAsignables(pageableFilters);
    if (!preview.fulfilled) {
      errorNotification(
        preview.gettingCmsClasesAsignablesError ||
          "Error al obtener las clases o programas deportivos"
      );
    }
  };

  onExport = () => {
    // TODO: Verificar si se necesita
    // this.setState({ downloading: true });
  };

  onVerMembresia = (row: DeportivaEventoAsignable) => {
    const idClubBase = row.idClubMembresia;
    const idPublico = row.idPublico;
    window.open(
      `/membresias-socios?idClub=${idClubBase}&idPublico=${idPublico}`,
      "_blank"
    );
  };

  onAsignar = (rowPopup: DeportivaEventoAsignable) => {
    if (rowPopup.esClasePersonalizada) {
      this.setState({
        rowPopup,
        clasePopupOpen: true,
        clasePopupTitle: "Asignar clase",
        clasePopupText: "Asignar clase"
      });
    } else {
      this.setState({
        rowPopup,
        programaPopupOpen: true,
        programaPopupTitle: "Asignar programa",
        programaPopupText: "Asignar programa"
      });
    }
  };

  asignar = async (values: any) => {
    this.setState({ waiting: true });
    const idProductoInstancia = this.state.rowPopup.idProductoInstancia;

    const body = this.state.rowPopup.esClasePersonalizada
      ? {
          idProductoInstancia,
          idEmpleado: values.idEmpleado,
          fechaClase: values.fechaClase,
          horaClase: values.horaClase,
          ignorarPuesto: values.ignorarPuesto === "true",
          razonIgnorarPuesto: values.razonIgnorarPuesto
        }
      : {
          idProductoInstancia,
          idEmpleado: values.idEmpleado,
          anio: values.anio,
          mes: values.mes,
          ignorarPuesto: values.ignorarPuesto === "true",
          razonIgnorarPuesto: values.razonIgnorarPuesto
        };

    const preview = await this.props.postCmsAsignacion(body);

    if (!preview.fulfilled) {
      this.setState({ waiting: false });
      errorNotification(
        this.props.postingCmsAsignacionError ||
          "Error al asignar el evento, intente de nuevo por favor"
      );
    } else {
      this.setState({
        waiting: false,
        clasePopupOpen: false,
        programaPopupOpen: false
      });
      this.fetchData({} as any);
    }
  };

  render() {
    return (
      <Container>
        <FormBuilderModal
          isOpen={this.state.clasePopupOpen}
          title={this.state.clasePopupTitle}
          message={this.state.clasePopupText}
          ok={this.asignar}
          cancel={() => this.setState({ clasePopupOpen: false })}
          formConfig={formConfigClase}
          size={"md"}
        />

        <FormBuilderModal
          isOpen={this.state.programaPopupOpen}
          title={this.state.programaPopupTitle}
          message={this.state.programaPopupText}
          ok={this.asignar}
          cancel={() => this.setState({ programaPopupOpen: false })}
          formConfig={formConfigPrograma}
          size={"md"}
        />

        <FormBuilder<Inputs, Buttons>
          config={formConfig}
          submit={this.submit}
          processing={this.props.gettingCmsClasesAsignables}
        >
          {/* Selector de productos */}
          <ITypeSelectBuilder<Inputs>
            name="idProducto"
            data={this.props.cmsProductosOperacion || []}
            mapOption={option => ({
              label: option.nombre,
              value: option.id
            })}
            isLoading={this.props.gettingCmsProductosOperacion}
          />
        </FormBuilder>

        <hr className="row" />

        <MemoTable
          page={
            this.props.cmsClasesAsignables ||
            ({} as Page<DeportivaEventoAsignable>)
          }
          loading={
            this.state.downloading || this.props.gettingCmsClasesAsignables
          }
          onFetchData={this.fetchData}
          onExport={this.onExport}
          onAsignar={this.onAsignar}
          onVerMembresia={this.onVerMembresia}
        />
      </Container>
    );
  }
}
