import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";

import ModalFrame from "../../../toolkit/modalFrame";
import s from "./styles.scss";

import { Button, Container, Alert, Form } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";

const ACTIVITY_STATUS = ["COMPLETADA", "INCOMPLETA"];

interface Props {
  isOpen: boolean;
  toggle: () => void;
  editStatusActivity: (activityId: String, status: string) => void;
  changingActivityStatus: boolean;
  changingActivityStatusError: any;
  activityId: string;
}

interface State {
  fields: {
    status: string;
  };
}

class StatusActivityModal extends React.Component<Props, State> {
  state = {
    fields: {
      status: ACTIVITY_STATUS[0]
    },
    editing: false,
    errors: {}
  };

  onSubmit = e => {
    e.preventDefault();
    this.props.editStatusActivity(
      this.props.activityId,
      this.state.fields.status
    );
  };

  onChangeFactory = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: value
        };

        return { ...state, error: {}, fields };
      });
    };
  };

  render() {
    return (
      <ModalFrame
        title={"Cambiar estatus de la actividad"}
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
      >
        <Container>
          <Form>
            <BaseInput
              label={"Estatus de la actividad"}
              name={"status"}
              type="select"
              id={"status"}
              placeholder="Estatus de la actividad"
              value={this.state.fields["status"]}
              errors={[]}
              options={[
                { value: "COMPLETADA", label: "COMPLETADA" },
                { value: "INCOMPLETA", label: "INCOMPLETA" },
                { value: "NO_LLEGO", label: "NO LLEGÓ" },
                { value: "REAGENDO", label: "REAGENDÓ" },
                {
                  value: "NO_ENCONTRO_LO_QUE_BUSCABA",
                  label: "NO ENCONTRÓ LO QUE BUSCABA"
                },
                { value: "MOTIVOS_DE_NO_COMPRA", label: "MOTIVOS DE NO COMPRA" }
              ].map(({ value, label }) => ({
                value: value,
                label: label
              }))}
              onChange={this.onChangeFactory("status")}
            />
            <Button
              className={`${s.buttonMarginTop} ${s.primaryButton}`}
              onClick={e => this.onSubmit(e)}
              size="lg"
              disabled={this.props.changingActivityStatus}
              block
            >
              {this.props.changingActivityStatus
                ? "Cambiando"
                : "Cambiar Estatus"}
            </Button>
            {this.props.changingActivityStatusError && (
              <span>
                <hr />
                <Alert color="danger">Ocurrió un error inesperado.</Alert>
              </span>
            )}
          </Form>
        </Container>
      </ModalFrame>
    );
  }
}

export default withRouter(withStyles(s)(StatusActivityModal));
