import {
  faCalendarAlt,
  faCalendarPlus,
  faClipboardList,
  faDollarSign,
  faExchangeAlt,
  faPencilAlt,
  faPhone,
  faTag,
  faTimes,
  faUserCheck,
  faUserPlus
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import debounce from "lodash.debounce";
import toString from "lodash.tostring";
import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import ReactTable from "react-table";
import {
  Button,
  Col,
  Container,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  UncontrolledTooltip
} from "reactstrap";
import BaseAlphanumericInput from "../../../toolkit/baseAlphanumericInput";
import BaseInput from "../../../toolkit/baseInput";
import { INTEREST_STATUS } from "../../../types-business/User";
import accessCodes from "../../../utils/accessCodes";
import {
  allowedTo,
  userHasAnyPermissions,
  userHasPermission
} from "../../../utils/accessTree";
import CONSTANTS from "../../../utils/constants";
import { handleRequestError } from "../../../utils/helpers";
import permissionCodes from "../../../utils/permissionCodes";
import {
  getAllMaintenancesAvailableToSell,
  getAllMembershipsAvailableToSell,
  getClubsRequest,
  getSellersCatalog
} from "../../actions/catalogs";
import { getEmpresasConvenioRequest } from "../../actions/catalogs";
import { getProspectRequest } from "../../actions/prospects";
import MexicanCurrency from "../common/currency";
import ReassignProspectModal from "../reassignProspect";
import { EMPRESA_CONVENIO, Props, State } from "./interfaces";
import s from "./styles.scss";
import TabCalendar from "./tabs/tabCalendar";
import TabContentAgreements from "./tabs/tabContentAgreements";
import TabContentPromotions from "./tabs/tabContentPromotions";
if (process.env.BROWSER) require("bootstrap");
const { PROSPECT_CREATION, PROSPECTS_REASSIGNATION } = accessCodes;

const DEFAULT_PAGE_SIZE = 100;

const getContextualStatusClass = status => {
  switch (status) {
    case INTEREST_STATUS.STATUS_INTERESADO:
      return "table-success";
    case INTEREST_STATUS.STATUS_NO_INTERESADO:
      return "table-danger";
    case INTEREST_STATUS.STATUS_POSIBLEMENTE:
      return "table-warning";
    default:
      return "";
  }
};

class Prospects extends React.Component<Props, State> {
  state = {
    selectedClub: "",
    selectedSeller: "",
    activeTab: "1",
    sellers: [],
    search: {},
    listConvenio: {},
    searchPromotions: {},
    searchAgreements: {},
    agreements: [],
    fechaInicio: "",
    fechaFin: "",
    prospectSelected: [],
    isModalReassingOpen: false,
    promotionsFilters: {
      clubId: "",
      mantenimientoId: "",
      membresiaId: ""
    },
    agreementsFilters: {
      clubId: "",
      mantenimientoId: "",
      membresiaId: ""
    },
    columnsPromotions: [
      { Header: "Nombre", accessor: "nombre" },
      { Header: "Inicio de vigencia", accessor: "inicioVigencia" },
      { Header: "Fin de vigencia", accessor: "finVigencia" }
    ],
    columnsAgreements: [
      { Header: "Nombre", accessor: "nombre" },
      { Header: "Inicio de vigencia", accessor: "inicioVigencia" },
      { Header: "Fin de vigencia", accessor: "finVigencia" }
    ],
    nombre: "",
    asignadoA: null,
    convenioId: null,
    empresaId: null,
    clubId: null,
    folio: null,
    fechaCreacion: "",
    prSize: DEFAULT_PAGE_SIZE,
    prPage: 0,

    clubsCatalog: [],
    searching: false,
    prospects: { content: [], totalPages: 0 },

    empresasConvenio: [],
    empresasConvenioLoading: false,
    maintenancesAvailableToSell: [],
    membershipsAvailableToSell: [],
    sellersCatalog: null
  };

  modalReassingToggle = () => {
    this.setState(state => {
      const isModalReassingOpen = !this.state.isModalReassingOpen;
      return { ...state, isModalReassingOpen };
    });
  };

  componentDidMount() {
    this.getClubsCatalog();
    this.getSellersCatalog();
    this.getMembershipsAvailableToSell();
    this.getMaintenancesAvailableToSell();
  }

  componentDidUpdate() {
    if (this.props.reloadProspects) {
      this.props.clearCreatedProspectVariable();

      this.setState({ prospectSelected: [], searching: true }, () => {
        getProspectRequest(this.state.search).then(({ data }) => {
          this.setState({ prospects: data, searching: false });
        });
      });
    }

    if (!this.state.sellers.length && this.state.sellersCatalog) {
      this.setState(() => ({
        sellers: this.getOptionsSellers()
      }));
    }
    if (!this.state.agreements.length && this.props.empresasActivas.length) {
      this.setState(() => ({
        agreements: this.getOptionsAgreements()
      }));
    }
  }

  getClubsCatalog = () => {
    getClubsRequest()
      .then(({ data }) => {
        this.setState({ clubsCatalog: data });
      })
      .catch(handleRequestError);
  };

  getAggreementsCompanies = () => {
    if ((this.state.empresasConvenio || []).length <= 0) {
      getEmpresasConvenioRequest()
        .then(({ data }) => {
          this.setState({ empresasConvenio: data });
        })
        .catch(handleRequestError);
    }
  };

  getMembershipsAvailableToSell = () => {
    getAllMembershipsAvailableToSell()
      .then(({ data }) => {
        this.setState({ membershipsAvailableToSell: [...data] });
      })
      .catch(handleRequestError);
  };

  getMaintenancesAvailableToSell = () => {
    getAllMaintenancesAvailableToSell()
      .then(({ data }) => {
        this.setState({ maintenancesAvailableToSell: [...data] });
      })
      .catch(handleRequestError);
  };

  getSellersCatalog = () => {
    getSellersCatalog()
      .then(({ data }) => {
        this.setState({ sellersCatalog: data });
      })
      .catch(handleRequestError);
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  getOptionsSellers = () => {
    const { sellersCatalog } = this.state;
    return (sellersCatalog || []).map(seller => {
      const { userId, name, firstSurname, secondSurname } = seller;
      return {
        value: toString(userId),
        label: `${name || ""} ${firstSurname || ""} ${secondSurname || ""}`
          .toUpperCase()
          .trim()
      };
    });
  };

  handlePromotionsRowClick = promotion => {
    this.props.togglePromotionDetailModal(promotion);
  };

  handleAgreementsRowClick = agreement => {
    this.props.toggleAgreementDetailModal(agreement);
  };

  popoverTemplate = [
    '<div class="timePickerWrapper popover">',
    '<div class="arrow"></div>',
    '<div class="popover-content">',
    "</div>",
    "</div>"
  ].join("");

  eventRenderContent = event => {
    return [
      `<div class="timePickerCanvas">
            <b>Prospecto: </b> ${event.persona} <br>
            <b>Teléfono: </b> ${event.phone} <br>
            <b>Nota: </b> ${event.note} <br>
            <b>Estatus: </b> ${event.status}
      </div>`
    ].join("");
  };

  getOptionsAgreements = () => {
    const aggrementsOptions = [];
    const { empresasActivas } = this.props;
    empresasActivas &&
      empresasActivas.forEach(aggrement => {
        const { empresaId, empresa } = aggrement;
        aggrementsOptions.push({
          value: toString(empresaId),
          label: empresa
        });
      });

    return aggrementsOptions;
  };

  onFetchData = async ({ pageSize: size, page }) => {
    const filters = {
      nombre: this.state.nombre,
      asignadoA: this.state.asignadoA,
      convenioId: this.state.convenioId,
      empresaId: this.state.empresaId,
      clubId: this.state.clubId,
      folio: this.state.folio,
      fechaCreacion: this.state.fechaCreacion,
      size,
      page
    };

    const search = { ...filters };

    await this.setState({
      search,
      prPage: page,
      prSize: size,
      prospectSelected: [],
      searching: true
    });

    //console.info("fetchData");
    getProspectRequest(search).then(({ data }) => {
      this.setState({ prospects: data, searching: false });
    });
  };

  searchData = async () => {
    const filters = {
      nombre: this.state.nombre,
      asignadoA: this.state.asignadoA,
      convenioId: this.state.convenioId,
      empresaId: this.state.empresaId,
      clubId: this.state.clubId,
      folio: this.state.folio,
      fechaCreacion: this.state.fechaCreacion,
      size: this.state.prSize,
      page: this.state.prPage
    };

    const search = { ...filters };

    await this.setState({
      search,
      prospectSelected: [],
      searching: true
    });

    //console.info("searchData");
    getProspectRequest(search).then(({ data }) => {
      this.setState({ prospects: data, searching: false });
    });
  };

  onFetchDataPromotions = ({ pageSize: size, page, ...args }) => {
    const filters = args.filtered.reduce(
      (accum, { id, value }) => ({ ...accum, [id]: value }),
      {}
    );
    const searchPromotions = {
      ...filters,
      ...this.state.promotionsFilters,
      tipoUso: "VENTA",
      soloVigentes: true,
      size,
      page
    };
    this.props.getPromotions(searchPromotions);
    this.setState(() => ({ searchPromotions }));
  };

  onFetchDataAgreements = ({ pageSize: size, page, ...args }) => {
    const filters = args.filtered.reduce(
      (accum, { id, value }) => ({ ...accum, [id]: value }),
      {}
    );
    const searchAgreements = {
      ...filters,
      ...this.state.agreementsFilters,
      size,
      page
    };
    this.setState(() => ({ searchAgreements }));
  };

  debouncedOnFetchDataPromotions = debounce(
    this.onFetchDataPromotions,
    CONSTANTS.DEBOUNCE_INPUT_TIME
  );

  debouncedOnFetchDataAgreements = debounce(
    this.onFetchDataAgreements,
    CONSTANTS.DEBOUNCE_INPUT_TIME
  );

  debouncedOnFetchData = debounce(
    this.onFetchData,
    CONSTANTS.DEBOUNCE_INPUT_TIME
  );

  selectClub = event => {
    const clubId = event.target.value;
    this.setState({
      selectedClub: clubId,
      selectedSeller: ""
    });
    this.props.getClubSellers(clubId);
    this.props.getClubGeneralManagers(clubId);
  };

  onChangePromotionFilter = (event, name) => {
    const value = event.target.value;
    const promotionsFilters = {
      ...this.state.promotionsFilters,
      [`${name}`]: value
    };
    this.setState(state => ({
      ...state,
      promotionsFilters
    }));
  };

  onChangeAgreementFilter = (event, name) => {
    const value = event.target.value;
    const agreementsFilters = {
      ...this.state.agreementsFilters,
      [`${name}`]: value
    };
    this.setState(state => ({
      ...state,
      agreementsFilters
    }));
  };

  selectSeller = event => {
    const sellerId = event.target.value;
    this.setState(() => ({
      selectedSeller: sellerId
    }));
  };

  handleSaveReassignment = async (type, id) => {
    const response = await this.props.saveReassignment(
      type,
      id,
      this.state.prospectSelected
    );
    if (response.value.status == 200) {
      this.modalReassingToggle();

      this.setState(
        {
          prospectSelected: [],
          searching: true
        },
        () => {
          //console.info("handleSaveReassignment");
          getProspectRequest(this.state.search).then(({ data }) => {
            this.setState({ prospects: data, searching: false });
          });
        }
      );
    }
    return response;
  };

  searchPromotions = () => {
    const search = {
      ...this.state.searchPromotions,
      ...this.state.promotionsFilters
    };
    this.props.getPromotions(search);
  };

  searchAgreements = () => {
    const agreementsFilters = this.state.agreementsFilters;
    this.props.getEmpresasActivas(
      agreementsFilters.clubId,
      agreementsFilters.mantenimientoId
    );
  };

  handleCheckProspects = (e, id) => {
    const value = e.target.checked;
    const prospectSelected = this.state.prospectSelected;
    if (value) {
      prospectSelected.push(id);
    } else {
      const index = prospectSelected.indexOf(id);
      prospectSelected.splice(index, 1);
    }
    this.setState({ prospectSelected });
  };

  validarEdad = fechaNacimiento => {
    let fechaNac = new Date(fechaNacimiento);
    let fechaActual = new Date();

    const mes = fechaActual.getMonth();
    const dia = fechaActual.getDate();
    const año = fechaActual.getFullYear();

    fechaActual.setDate(dia);
    fechaActual.setMonth(mes);
    fechaActual.setFullYear(año);

    const edad = Math.floor(
      (fechaActual - fechaNac) / (1000 * 60 * 60 * 24) / 365
    );

    return edad;
  };

  goToQuotations = prospect => {
    let edad = this.validarEdad(prospect.fechaNacimiento);
    this.props.history.push(
      `/cotizaciones/prospecto/${prospect.personaId}/${edad}`
    );
  };

  eventRender = (event, element, view) => {
    element.popover({
      title: event.title,
      content: this.eventRenderContent(event),
      trigger: "hover",
      placement: "top",
      container: "body",
      html: true
    });

    let markup;
    if (view.name === "agendaDay") {
      markup = (
        <div
          onClick={() => this.props.toggleChangeStatus(event.activityId)}
          style={{
            backgroundColor: "#84432",
            color: "#FFF"
          }}
        >
          <span
            style={{
              backgroundColor: "#3a87ad",
              color: "#FFF",
              display: "inline-block",
              width: "100%",
              marginTop: "4px"
            }}
          >
            {event.title}
          </span>
        </div>
      );
    } else if (view.name === "agendaWeek") {
      markup = (
        <div onClick={() => this.props.toggleChangeStatus(event.activityId)}>
          <FontAwesomeIcon
            style={{
              margin: "3 auto",
              display: "block",
              color: "white"
            }}
            icon={event.title == "AGENDA" ? faCalendarAlt : faPhone}
          />
        </div>
      );
    } else {
      markup = (
        <div onClick={() => this.props.toggleChangeStatus(event.activityId)}>
          Evento
        </div>
      );
    }

    ReactDOM.render(markup, element[0]);
  };

  render() {
    const columns: any = [
      {
        Header: "Folio",
        id: "folioProspecto",
        accessor: "folio"
      },
      {
        Header: "Nombre",
        id: "nombre",
        accessor: d => {
          if (d.esCliente) {
            return (
              <span>
                <a
                  id={"vm-" + d.idMembresiaSocio}
                  href="#"
                  onClick={evt => {
                    evt.preventDefault();
                    const club = d.idClubBase;
                    const idPublico = d.idPublico;
                    window.open(
                      `/membresias-socios?idClub=${club}&idPublico=${idPublico}`,
                      "_blank"
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faUserCheck} color="#06f" />
                  {" (CLIENTE): " + d.nombreCompleto}
                </a>
                <UncontrolledTooltip
                  placement="left"
                  target={"vm-" + d.idMembresiaSocio}
                >
                  Memb. {d.idPublico} de {d.clubBase}
                </UncontrolledTooltip>
              </span>
            );
          }

          return `${d.nombreCompleto} ${
            !!d.esInvitadoGratis ? "(Invitado gratis)" : ""
          }`;
        }
      },
      {
        Header: "Club",
        id: "clubAsignado",
        accessor: "clubNombre"
      },
      {
        Header: "Asignado",
        id: "asignadoA",
        accessor: "asignadoANombre"
      },
      {
        Header: "Convenio",
        id: "convenioId",
        accessor: "convenioNombre"
      },
      {
        Header: () => (
          <div className="w-100 text-center">
            {allowedTo(PROSPECT_CREATION) && (
              <Button
                color="link"
                onClick={() => this.props.toggleModal()}
                className={`${s.innerButton} ${s.primaryLink}`}
              >
                <FontAwesomeIcon icon={faUserPlus} />
              </Button>
            )}
            {allowedTo(PROSPECTS_REASSIGNATION) &&
              this.state.prospectSelected.length > 0 && (
                <Button
                  color="link"
                  onClick={() => this.modalReassingToggle()}
                  className={`${s.innerButton} ${s.primaryLink}`}
                >
                  <FontAwesomeIcon icon={faExchangeAlt} />
                </Button>
              )}
          </div>
        ),
        id: "fechaCreacion",
        accessor: prospect => {
          if (prospect.esCliente) {
            return null;
          }

          return (
            <div className={`${s.buttonsContainer} ${s.myStyle}`}>
              <Row>
                {userHasPermission(
                  permissionCodes.PROSPECT_CHANGE_INTEREST_STATUS_PERMISSION
                ) && (
                  <Col className={s.marginLeftNil} xs={2}>
                    <a
                      id={`faTag${prospect.personaId}`}
                      onClick={() => this.props.toggleModalStatus(prospect)}
                      className={`${s.statusAnchor}`}
                    >
                      <FontAwesomeIcon icon={faTag} />
                    </a>
                    <UncontrolledTooltip
                      placement="left"
                      target={`faTag${prospect.personaId}`}
                    >
                      Estatus de interés
                    </UncontrolledTooltip>
                  </Col>
                )}
                {userHasPermission(
                  permissionCodes.PROSPECT_CREATE_ACTIONS_PERMISSION
                ) && (
                  <Col className={`${s.plusPadding}`} xs={2}>
                    <a
                      id={`faCalendarPlus${prospect.personaId}`}
                      onClick={() => this.props.toggleModalActions(prospect)}
                      className={`${s.actionAnchor}`}
                    >
                      <FontAwesomeIcon icon={faCalendarPlus} />
                    </a>
                    <UncontrolledTooltip
                      placement="left"
                      target={`faCalendarPlus${prospect.personaId}`}
                    >
                      Acciones
                    </UncontrolledTooltip>
                  </Col>
                )}
                {userHasAnyPermissions([
                  permissionCodes.PROSPECT_BASIC_DATA_EDITION_PERMISSION,
                  permissionCodes.PROSPECT_DETAIL_EDITION_PERMISSION
                ]) && (
                  <Col className={s.marginLeftNil} xs={2}>
                    <a
                      id={`faPencilAlt${prospect.personaId}`}
                      onClick={() => {
                        window.open(
                          `/prospecto/${prospect.personaId}/detalle`,
                          "_blank"
                        );
                      }}
                      className={`${s.editAnchor}`}
                    >
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </a>
                    <UncontrolledTooltip
                      placement="left"
                      target={`faPencilAlt${prospect.personaId}`}
                    >
                      Editar Prospecto
                    </UncontrolledTooltip>
                  </Col>
                )}
                {userHasPermission(
                  permissionCodes.PROSPECT_VIEW_LOG_PERMISSION
                ) && (
                  <Col className={`${s.plusPadding}`} xs={2}>
                    <a
                      id={`faClipboardList${prospect.personaId}`}
                      onClick={() => this.props.toggleModalActionsLog(prospect)}
                      className={`${s.actionsLogAnchor}`}
                    >
                      <FontAwesomeIcon icon={faClipboardList} />
                    </a>
                    <UncontrolledTooltip
                      placement="left"
                      target={`faClipboardList${prospect.personaId}`}
                    >
                      Bitácora
                    </UncontrolledTooltip>
                  </Col>
                )}
                <Col className={`${s.plusPadding}`} xs={2}>
                  <a
                    id={`faDollarSign${prospect.personaId}`}
                    onClick={() => this.goToQuotations(prospect)}
                    className={`${s.actionAnchor}`}
                  >
                    <FontAwesomeIcon icon={faDollarSign} />
                  </a>
                  <UncontrolledTooltip
                    placement="left"
                    target={`faDollarSign${prospect.personaId}`}
                  >
                    Cotizaciones
                  </UncontrolledTooltip>
                </Col>
                {userHasPermission(
                  permissionCodes.PROSPECT_DELETE_PERMISSION
                ) ? (
                  <Col className={s.marginLeftNil} xs={2}>
                    <a
                      id={`faTimes${prospect.personaId}`}
                      onClick={() => this.props.toggleDeleteModal(prospect)}
                      className={`${s.deleteAnchor}`}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </a>
                    <UncontrolledTooltip
                      placement="left"
                      target={`faTimes${prospect.personaId}`}
                    >
                      Eliminar
                    </UncontrolledTooltip>
                  </Col>
                ) : null}
              </Row>
            </div>
          );
        }
      }
    ];

    if (allowedTo(PROSPECTS_REASSIGNATION)) {
      columns.unshift({
        Header: "",
        filterable: false,
        id: "seleccionado",
        minWidth: 22,
        accessor: prospect => {
          return (
            <div className={`${s.checkboxContainer} ${s.myStyle}`}>
              <Row>
                <Col>
                  <Input
                    name="prospectSelected"
                    type="checkbox"
                    id="prospectSelected"
                    checked={
                      this.state.prospectSelected.indexOf(prospect.personaId) >
                      -1
                    }
                    onChange={e =>
                      this.handleCheckProspects(e, prospect.personaId)
                    }
                  />
                </Col>
              </Row>
            </div>
          );
        }
      });
    }

    const userAllowedClubs = ((this.props.userContext || {}).clubs || []).map(
      allowedClub => allowedClub.externalId
    );

    const allowedClubs = (this.state.clubsCatalog || [])
      .filter(club => !!club.migradoCrm2)
      .filter(club => {
        return !!userAllowedClubs.length
          ? userAllowedClubs.indexOf(club.clubId) >= 0
          : true;
      });

    return (
      <Container className="p-0 my-3">
        {this.state.isModalReassingOpen && (
          <ReassignProspectModal
            isOpen={this.state.isModalReassingOpen}
            toggle={this.modalReassingToggle}
            clubs={[...this.state.clubsCatalog]}
            sellers={this.state.sellersCatalog}
            save={this.handleSaveReassignment}
            saving={this.props.savingReassignment}
            savingError={this.props.saveReassignmentError}
          />
        )}
        <Row className="h-100">
          <Col xs={8}>
            {/* Filtros de búsqueda de los prospectos */}
            <Form
              onSubmit={e => {
                e.preventDefault();
                this.searchData();
              }}
            >
              <Row>
                <Col sm="2">
                  <BaseAlphanumericInput
                    id="filterProspectFolio"
                    name="filterProspectFolio"
                    label={"Folio"}
                    value={this.state.folio}
                    placeholder={"Folio"}
                    onChange={e => {
                      this.setState({ folio: e.target.value || null });
                    }}
                  />
                </Col>

                <Col sm="4">
                  <BaseInput
                    label={"Nombre del prospecto"}
                    name="nombre"
                    type="text"
                    id="nombre"
                    placeholder="Nombre"
                    value={this.state.nombre}
                    onChange={e => {
                      this.setState({ nombre: e.target.value });
                    }}
                  />
                </Col>

                <Col sm="2">
                  <BaseInput
                    type="select"
                    id={"filterClub"}
                    name={"filterClub"}
                    label={"Club"}
                    placeholder="Club"
                    value={this.state.clubId}
                    size="sm"
                    onChange={e => {
                      this.setState({ clubId: e.target.value || null });
                    }}
                    options={[
                      { value: "", label: "Seleccionar un club" }
                    ].concat(
                      [...allowedClubs].map(option => ({
                        value: option.clubId,
                        label: option.nombre
                      }))
                    )}
                  />
                </Col>

                <Col sm="4">
                  <Label className={s.label}>Vendedor asignado:</Label>
                  <Typeahead
                    id={"asignadoA"}
                    promptText={"Vendedor asignado"}
                    bsSize={"sm"}
                    onChange={sel =>
                      this.setState({
                        asignadoA: sel.length > 0 ? sel[0]["id"] : null
                      })
                    }
                    emptyLabel={"Sin coincidencias"}
                    disabled={this.state.sellers.length === 0}
                    maxResults={20}
                    options={this.state.sellers.map(item => ({
                      id: item.value,
                      label: item.label
                    }))}
                  />
                </Col>

                <Col sm="4">
                  <Label className={s.label}>Fecha de creación:</Label>
                  <Input
                    name="fechaCreacion"
                    type="date"
                    id="fechaCreacion"
                    size={"sm"}
                    placeholder="Fecha de creacion"
                    value={this.state.fechaCreacion}
                    onChange={e => {
                      this.setState({ fechaCreacion: e.target.value });
                    }}
                  />
                </Col>

                <Col sm="6">
                  <Label className={s.label}>Convenio:</Label>
                  <Typeahead
                    type="select"
                    id="convenioId"
                    name="convenioId"
                    label={"Convenio"}
                    promptText={"Convenio"}
                    bsSize={"sm"}
                    emptyLabel={"Sin coincidencias"}
                    options={(this.state.empresasConvenio || []).map(
                      (agreement: EMPRESA_CONVENIO) => ({
                        id: `${agreement.convenioId}-${agreement.empresaId}`,
                        label: agreement.empresa
                      })
                    )}
                    onChange={sel =>
                      this.setState({
                        convenioId:
                          sel.length > 0 ? sel[0]["id"].split("-")[0] : null,
                        empresaId:
                          sel.length > 0 ? sel[0]["id"].split("-")[1] : null
                      })
                    }
                    onMenuToggle={isOpen => {
                      if (isOpen) {
                        this.getAggreementsCompanies();
                      }
                    }}
                  />
                </Col>

                <Col sm="2">
                  <Label className={s.label}>&nbsp;</Label>
                  <Button
                    size="sm"
                    className={`${s.searchButton} ${s.primaryButton} float-right btn-block`}
                    disabled={this.state.searching}
                    type="submit"
                  >
                    Buscar
                  </Button>
                </Col>
              </Row>
            </Form>

            <div>&nbsp;</div>

            <ReactTable
              style={{ height: CONSTANTS.TABLES_HEIGHT }}
              manual
              data={this.state.prospects.content || null}
              pages={this.state.prospects.totalPages || null}
              columns={columns}
              className="w-100"
              onFetchData={this.debouncedOnFetchData}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              loading={this.state.searching}
              sortable={false}
              getTrProps={(_, prospect) =>
                prospect
                  ? {
                      className: getContextualStatusClass(
                        prospect.original.status
                      )
                    }
                  : {}
              }
              SubComponent={({ original }) => (
                <Container className={`${s.detailsContainer} py-2`} fluid>
                  <Row>
                    <Col className="text-capitalize">
                      {(original.nombreCompleto || "").toUpperCase()}
                    </Col>
                    <Col>{original.fechaNacimiento}</Col>
                  </Row>
                  <Row>
                    <Col>{(original.correo || "").toUpperCase()}</Col>
                    <Col>{(original.telefono || "").toUpperCase()}</Col>
                  </Row>
                  <Row>
                    <Col>{(original.direccion || "").toUpperCase()}</Col>
                  </Row>

                  <Row>
                    <Col>
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      CLUB: {`${original.clubNombre || ""}`.toUpperCase()}
                    </Col>
                    <Col>
                      ORIGEN:{" "}
                      {`${original.origen || "NO APLICA"}`.toUpperCase() +
                        (original.campania
                          ? ` (${original.campania})`.toUpperCase()
                          : "")}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      FECHA REGISTRO: {`${original.fechaRegistro || ""}`}
                    </Col>
                    <Col>HORA REGISTRO: {`${original.horaRegistro || ""}`}</Col>
                  </Row>

                  {(!!original.cotizacionPrecioMembresia ||
                    !!original.cotizacionPrecioMantenimiento ||
                    !!original.cotizacionMantenimiento) && (
                    <React.Fragment>
                      <Row>
                        <Col>
                          <hr />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          Precio membresía:{" "}
                          <MexicanCurrency
                            quantity={original.cotizacionPrecioMembresia || 0}
                          />
                        </Col>
                        <Col>
                          Mantenimiento:{" "}
                          {`${original.cotizacionMantenimiento || ""}`}
                        </Col>
                        <Col>
                          Precio Mantenimiento:{" "}
                          <MexicanCurrency
                            quantity={
                              original.cotizacionPrecioMantenimiento || 0
                            }
                          />
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </Container>
              )}
            />
          </Col>
          <Col xs={4}>
            <Container className="h-100 w-0 p-0">
              <Nav className={s.tabs} tabs>
                <NavItem>
                  <NavLink
                    className={`${
                      this.state.activeTab === "1" ? "active" : ""
                    } ${s.cursorPointer}`}
                    onClick={() => {
                      this.toggleTab("1");
                    }}
                  >
                    Promociones
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={`${
                      this.state.activeTab === "2" ? "active" : ""
                    } ${s.cursorPointer}`}
                    onClick={() => {
                      this.toggleTab("2");
                    }}
                  >
                    Convenios
                  </NavLink>
                </NavItem>
                {userHasPermission(
                  permissionCodes.PROSPECT_VIEW_APPOINMENT_SCHEDULE_PERMISSION
                ) && (
                  <NavItem>
                    <NavLink
                      className={`${
                        this.state.activeTab === "3" ? "active" : ""
                      } ${s.cursorPointer}`}
                      onClick={() => {
                        this.toggleTab("3");
                      }}
                    >
                      Agenda
                    </NavLink>
                  </NavItem>
                )}
              </Nav>
              <TabContent
                style={{ height: "615px" }}
                activeTab={this.state.activeTab}
              >
                <div className="p-0 m-0 w-100 h-100">
                  {this.state.activeTab === "1" && (
                    <TabContentPromotions
                      promotionsFilters={this.state.promotionsFilters}
                      onChangePromotionFilter={this.onChangePromotionFilter}
                      allowedClubs={[...allowedClubs]}
                      maintenances={this.state.maintenancesAvailableToSell}
                      memberships={this.state.membershipsAvailableToSell}
                      searchPromotions={this.searchPromotions}
                      columnsPromotions={this.state.columnsPromotions}
                      promotionsLoading={this.props.promotionsLoading}
                      promotions={this.props.promotions}
                      handlePromotionsRowClick={this.handlePromotionsRowClick}
                      debouncedOnFetchDataPromotions={
                        this.debouncedOnFetchDataPromotions
                      }
                    />
                  )}
                  {this.state.activeTab === "2" && (
                    <TabContentAgreements
                      agreementsFilters={this.state.agreementsFilters}
                      onChangeAgreementFilter={this.onChangeAgreementFilter}
                      allowedClubs={[...allowedClubs]}
                      maintenances={this.state.maintenancesAvailableToSell}
                      memberships={this.state.membershipsAvailableToSell}
                      searchAgreements={this.searchAgreements}
                      columnsAgreements={this.state.columnsAgreements}
                      agreementsLoading={this.props.empresasActivasLoading}
                      agreements={this.props.empresasActivas}
                      handleAgreementsRowClick={this.handleAgreementsRowClick}
                      debouncedOnFetchDataAgreements={
                        this.debouncedOnFetchDataAgreements
                      }
                    />
                  )}
                  {this.state.activeTab === "3" && (
                    <TabCalendar
                      allowedClubs={[...allowedClubs]}
                      selectedClub={this.state.selectedClub}
                      selectClub={this.selectClub}
                      selectedSeller={this.state.selectedSeller}
                      selectSeller={this.selectSeller}
                      clubSellers={[
                        ...(this.props.clubGeneralManagers || []).filter(
                          x => x.userId == this.props.userId
                        ),
                        ...(this.props.clubSellers || [])
                      ]}
                      eventRender={this.eventRender}
                      createdAction={this.props.createdAction}
                    />
                  )}
                </div>
              </TabContent>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(Prospects));
