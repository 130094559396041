import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import FullCalendar from "./fullcalendar";
import s from "./tabCalendarStyles.scss";
import moment from "moment";
import { Club } from "../../../../types-business/Clubs";
import axios from "axios";
import constants from "../../../../utils/constants";
import { Row, Container, Col, TabPane, Input } from "reactstrap";
import { allowedTo } from "../../../../utils/accessTree";
import accessCodes from "../../../../utils/accessCodes";
const { CONSULT_SCHEDULE } = accessCodes;

interface USER_BASIC_INFO {
  userId: string;
  name: string;
  firstSurname: string;
  secondSurname: string;
}

interface Props {
  allowedClubs: Club[];
  selectedClub: any;
  selectClub: any;
  selectedSeller: any;
  selectSeller: any;
  clubSellers: USER_BASIC_INFO[];
  eventRender: any;
  createdAction: any;
}

const transformEvent = event => {
  console.info(event);
  const res = event
    ? {
        title: event.tipo,
        start: utcDate(event.fechaInicio),
        end: utcDate(event.fechaFin),
        activityId: event.actividadId,
        phone: event.telefono || "N/D",
        persona: event.nombre,
        note: event.nota,
        status: event.status || ""
      }
    : event;

  return res;
};

const utcDate = (str: string): Date => {
  const date = new Date((str || "").replace("Z", ""));
  //date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  return date;
};

class TabCalendar extends React.Component<Props> {
  render() {
    return (
      <TabPane className="h-100" tabId="3">
        <div className="h-100" id="calendar-container">
          {this.props.allowedClubs.length >= 1 ? (
            <Container className="px-0" fluid>
              <Row>
                <Col className="pr-1">
                  <Input
                    value={this.props.selectedClub}
                    onChange={this.props.selectClub}
                    type="select"
                    name="select"
                    id="allowedClubs"
                    // disabled={this.props.allowedClubs.length === 1}
                    className={s.allowedClubs}
                    size="xs"
                  >
                    {this.props.allowedClubs.map(club => (
                      <option key={club.clubId} value={club.clubId}>
                        {club.nombre}
                      </option>
                    ))}
                  </Input>
                </Col>
                <Col className="pl-1">
                  <Input
                    value={this.props.selectedSeller}
                    onChange={this.props.selectSeller}
                    type="select"
                    name="select"
                    id="clubSellets"
                    disabled={this.props.clubSellers.length === 1}
                    className={s.allowedClubs}
                  >
                    <option key="-1" value="">
                      Seleccione un vendedor
                    </option>
                    {this.props.clubSellers.map(seller => (
                      <option key={seller.userId} value={seller.userId}>
                        {`${seller.name} ${seller.firstSurname ||
                          ""} ${seller.secondSurname || ""}`}
                      </option>
                    ))}
                  </Input>
                </Col>
              </Row>
            </Container>
          ) : null}
          {allowedTo(CONSULT_SCHEDULE) ? (
            <FullCalendar
              id="activities-calendar"
              header={{
                left: "prev,next,today",
                center: "title",
                right: "agendaWeek,agendaDay"
              }}
              titleFormat={"MMM D"}
              timeZone="UTC"
              eventRender={this.props.eventRender}
              createdAction={transformEvent(this.props.createdAction)}
              navLinks={true}
              eventLimit={true}
              events={(start, end, _, cb) => {
                const fechaInicio = moment(
                  start.toISOString(),
                  "YYYY-MM-DD"
                ).toISOString();
                const fechaFin = moment(
                  end.toISOString(),
                  "YYYY-MM-DD"
                ).toISOString();
                const tipo = "AGENDA,LLAMADA,CITA";
                const params = {
                  fechaInicio,
                  fechaFin,
                  tipo
                };
                {
                  this.props.selectedSeller.length != 0
                    ? axios
                        .get(
                          `${constants.BASE_URL}/api/activity/quotes/${this.props.selectedSeller}`,
                          {
                            withCredentials: true,
                            params
                          }
                        )
                        .then(({ data }) => {
                          cb(data.map(transformEvent));
                        })
                    : null;
                }
              }}
              defaultView={"agendaDay"}
              firstDay={"1"}
              lastDay={"7"}
              locale={"es"}
              selectedSeller={this.props.selectedSeller}
              height={700}
              weekends={true}
              views={{
                agenda: {
                  allDaySlot: false,
                  minTime: "06:00:00"
                }
              }}
              buttonText={{
                today: "Hoy",
                month: "Mes",
                week: "Semana",
                day: "Día",
                list: "Lista"
              }}
            />
          ) : null}
        </div>
      </TabPane>
    );
  }
}
export default withRouter(withStyles(s)(TabCalendar));
