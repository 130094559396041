import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { Button, UncontrolledTooltip } from "reactstrap";
import s from "../../styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileExport,
  faUserClock,
  faUsers,
  faUserTie
} from "@fortawesome/free-solid-svg-icons";
import permissionCodes from "../../../../../utils/permissionCodes";
import { userHasPermission } from "../../../../../utils/accessTree";
import DeportivaVenta from "../../../../../types/DeportivaVenta";

const {
  COMISIONES_V2_ACTUALIZAR_VENDEDOR: ACTUALIZAR_VENDEDOR,
  COMISIONES_V2_ACTUALIZAR_COORDINADOR: ACTUALIZAR_COORDINADOR,
  COMISIONES_V2_ACTUALIZAR_INSTRUCTOR_PRINCIPAL: ACTUALIZAR_INSTRUCTOR_PRINCIPAL
} = permissionCodes;

export interface TableProps {
  page: Page<DeportivaVenta>;
  loading: boolean;
  onFetchData: ({ page, pageSize }) => void;
  onExport: () => void;
  onChangeVendedor: (row: DeportivaVenta) => void;
  onChangeCoordinador: (row: DeportivaVenta) => void;
  onChangeInstructor: (row: DeportivaVenta) => void;
}

export const MemoTable = React.memo(
  ({
    page,
    loading,
    onFetchData,
    onExport,
    onChangeVendedor,
    onChangeCoordinador,
    onChangeInstructor
  }: TableProps) => {
    // State de las columnas de la tabla
    const [columns, setColumns] = useState(
      columnsFormatter(
        onChangeVendedor,
        onChangeCoordinador,
        onChangeInstructor
      )
    );

    const [pageNumber, setPageNumber] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);

    // Actualización de estilos
    useEffect(() => {
      setColumns(
        columnsFormatter(
          onChangeVendedor,
          onChangeCoordinador,
          onChangeInstructor
        )
      );
    }, []);

    // Actualización de datos de paginación
    useEffect(() => {
      if (page) {
        setPageNumber(page.number);
        setTotalPages(page.totalPages);
      }
    }, [page]);

    const mostrados = page && page.numberOfElements;
    const totales = page && page.totalElements;
    return (
      <>
        {/* Botón para exportar */}
        <div className="row">
          {!!page && !!page.content && !!page.content.length && (
            <>
              <div className="col-6 p-0" style={{ lineHeight: "40px" }}>
                <span>
                  Ventas mostradas: {mostrados} de {totales}
                </span>
              </div>
              <div
                className="col-6 p-0 text-right"
                style={{ lineHeight: "40px" }}
              >
                <Button
                  hidden
                  color="primary"
                  onClick={onExport}
                  disabled={loading}
                  className="mr-2"
                >
                  <FontAwesomeIcon icon={faFileExport} />
                  {!loading ? " Exportar" : " Espere..."}
                </Button>
              </div>
            </>
          )}
        </div>

        {/* Tabla */}
        <div className="row mt-2">
          <div style={{ width: "100%" }}>
            <ReactTable
              manual
              data={(page && page.content) || []}
              columns={columns}
              loading={loading}
              defaultPageSize={50}
              className="-striped -highlight"
              onFetchData={onFetchData}
              page={pageNumber}
              pages={totalPages}
              showPagination={true}
              showPageSizeOptions={false}
              getTrProps={() => ({ style: { height: "30px" } })}
              sortable={false}
            />
          </div>
        </div>
      </>
    );
  }
);

// TODO: Definir permisos para los botones
function columnsFormatter(
  onChangeVendedor: (row: DeportivaVenta) => void,
  onChangeCoordinador: (row: DeportivaVenta) => void,
  onChangeInstructor: (row: DeportivaVenta) => void
) {
  return [
    {
      Header: "Factura",
      id: "folioFactura",
      width: 88,
      className: s.tdClear,
      headerClassName: "text-left",
      accessor: simpleAccessor("folioFactura")
    },
    {
      Header: "Producto",
      id: "producto",
      className: s.tdClear,
      headerClassName: "text-left",
      accessor: simpleAccessor("producto")
    },
    {
      Header: "Vendedor",
      id: "vendedor",
      className: s.tdClear,
      headerClassName: "text-left",
      accessor: simpleAccessor("vendedor")
    },
    {
      Header: "Coordinador",
      id: "coordinador",
      className: s.tdClear,
      headerClassName: "text-left",
      accessor: simpleAccessor("coordinador")
    },
    {
      Header: "Instructor principal",
      id: "instructorPrincipal",
      className: s.tdClear,
      headerClassName: "text-left",
      accessor: simpleAccessor("instructorPrincipal")
    },
    {
      Header: "",
      id: "acciones",
      className: s.tdClear,
      width: 120,
      accessor: (row: DeportivaVenta) => {
        return (
          <div className={`${fieldClassName(row)} text-center`}>
            {renderBotonVendedor(row, onChangeVendedor)}
            {renderBotonCoordinador(row, onChangeCoordinador)}
            {renderBotonInstructorPrincipal(row, onChangeInstructor)}
          </div>
        );
      }
    }
  ];
}

function simpleAccessor(key: keyof DeportivaVenta) {
  return (row: DeportivaVenta) => {
    return <div className={fieldClassName(row)}>{row[key]}&nbsp;</div>;
  };
}

function fieldClassName(row: DeportivaVenta, key?: keyof DeportivaVenta) {
  if (!key) {
    return `${s.field}`;
  }

  switch (key) {
    case "vendedor":
      if (!row.aplicaComisionVenta) {
        return `${s.field} ${s.noComisionable}`;
      }
      if (!row.errorComisionVenta) {
        return `${s.field} ${s.errorComision}`;
      }
      return `${s.field}`;
    case "coordinador":
      if (!row.aplicaComisionCoordinacion) {
        return `${s.field} ${s.noComisionable}`;
      }
      if (!row.errorComisionCoordinacion) {
        return `${s.field} ${s.errorComision}`;
      }
      return `${s.field}`;
    case "instructorPrincipal":
      if (!row.aplicaComisionClase) {
        return `${s.field} ${s.noComisionable}`;
      }
      if (!row.errorComisionClase) {
        return `${s.field} ${s.errorComision}`;
      }
      return `${s.field}`;
    default:
      return `${s.field}`;
  }
}

function renderBotonVendedor(row: DeportivaVenta, action) {
  if (!userHasPermission(ACTUALIZAR_VENDEDOR)) {
    return false;
  }

  const idBoton = "id-" + row.idProductoInstancia + "-vendedor";
  let colorButton = "#004d00";
  let tooltip = "Actualizar al vendedor";
  let habilitado = true;

  if (!row.aplicaComisionVenta) {
    colorButton = "#e6b800";
    tooltip =
      "Puede actualizar al vendedor, pero el producto no aplica comisión por venta";
  }

  if (row.comisionVentaAplicada) {
    colorButton = "#004d00";
    tooltip = "La comisión por venta ya se generó y se reasignará";
  }

  if (row.comisionVentaPagada) {
    habilitado = false;
    colorButton = "#600";
    tooltip = "No puede cambiar al vendedor porque la comisión ya se pagó";
  }

  return renderBoton(
    row,
    idBoton,
    habilitado,
    action,
    tooltip,
    colorButton,
    faUserTie
  );
}

function renderBotonCoordinador(row: DeportivaVenta, action) {
  if (!userHasPermission(ACTUALIZAR_COORDINADOR)) {
    return false;
  }

  const idBoton = "id-" + row.idProductoInstancia + "-coordinador";
  let colorButton = "#004d00";
  let tooltip = "Actualizar al coordinador";
  let habilitado = true;

  if (row.comisionCoordinacionAplicada) {
    colorButton = "#e6b800";
    tooltip = row.aplicaComisionCoordinacionParcial
      ? "Ya se habían generado algunas comisiones por coordinación de clases pasadas, esas no se modificarán"
      : "Esta comisión ya se generó y se reasignará";
  } else if (row.aplicaComisionCoordinacionParcial) {
    colorButton = "#004d00";
    tooltip =
      "Las comisiones se generarán conforme se impartan las clases (coordinación parcial)";
  }

  if (row.aplicaComisionCoordinacionParcial && !row.eventosDisponibles) {
    habilitado = false;
    colorButton = "#600";
    tooltip = "Las clases ya se impartieron, no puede cambiar al coordinador";
  }

  if (habilitado && !row.aplicaComisionCoordinacion) {
    habilitado = false;
    colorButton = "#600";
    tooltip = "El producto no está configurado para aceptar coordinadores";
  }

  if (habilitado && row.comisionCoordinacionPagada) {
    habilitado = false;
    colorButton = "#600";
    tooltip = "Esta comisión ya se pagó, no puede cambiar al coordinador";
  }

  return renderBoton(
    row,
    idBoton,
    habilitado,
    action,
    tooltip,
    colorButton,
    faUserClock
  );
}

function renderBotonInstructorPrincipal(row: DeportivaVenta, action) {
  if (!userHasPermission(ACTUALIZAR_INSTRUCTOR_PRINCIPAL)) {
    return false;
  }

  const idBoton = "id-" + row.idProductoInstancia + "-instructor";
  let colorButton = "#008080";
  let tooltip = "Solo afectará a clases que no estén asignadas";
  let habilitado = true;

  if (!row.aplicaComisionClase) {
    habilitado = false;
    colorButton = "#600";
    tooltip =
      "No puede especificar a un instructor porque el producto no está configurado para impartir clases";
  }

  if (habilitado && !row.eventosDisponibles) {
    habilitado = false;
    colorButton = "#600";
    tooltip = "Las clases ya se impartieron, no puede cambiar al instructor";
  }

  return renderBoton(
    row,
    idBoton,
    habilitado,
    action,
    tooltip,
    colorButton,
    faUsers
  );
}

function renderBoton(
  row: DeportivaVenta,
  id,
  habilitado,
  action,
  tooltip,
  color,
  icon
) {
  if (habilitado) {
    return (
      <span id={id} className={s.btn} onClick={() => action(row)}>
        <FontAwesomeIcon icon={icon} color={color} />
        <UncontrolledTooltip placement="left" target={id}>
          {tooltip}
        </UncontrolledTooltip>
      </span>
    );
  } else {
    return (
      <span id={id} className={s.info}>
        <FontAwesomeIcon icon={icon} color={color} />
        <UncontrolledTooltip placement="left" target={id}>
          {tooltip}
        </UncontrolledTooltip>
      </span>
    );
  }
}
